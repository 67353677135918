import { useEffect, useRef, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Box,
  Typography,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { BrandSelect } from 'components/Forms/BrandSelect'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { SpiClient } from 'lib/SpiClient.ts'
import _ from 'lodash'
import { selectedOrganization } from 'config/cache.ts'
import { Brand, DesignerTemplate, Sku } from 'lib/graphql/types.ts'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { CheckCircle } from '@mui/icons-material'
import { BRANDS_LIST } from 'lib/graphql/listBrands.ts'
import { CREATE_TEMPLATE } from 'lib/graphql/createTemplate.ts'
import { UPDATE_TEMPLATE } from 'lib/graphql/updateTemplate.ts'
import {
  GetAllDesignerTemplates,
  GetAllDesignerTemplatesRef,
} from 'pages/VendorApp/Common/GetAllDesignerTemplates.tsx'
import { GetAllCatalogSkus, GetAllCatalogSkusRef } from 'pages/VendorApp/Common/GetAllCatalogSkus.tsx'

const initialValues = {
  brandId: '',
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  brandId: Yup.string().required('Required'),
})

type TemplateImportFieldProps = {
  brandId: string
}

type TemplateNameEntityType = 'BRAND' | 'SKU' | 'LABEL_SIZE' | 'DISPLAY_NAME'
const getFromTemplateName = (templateName: string, entity: TemplateNameEntityType) => {
  try {
    if (entity === 'BRAND') {
      return templateName.split('_')[0]
    }
    if (entity === 'SKU') {
      return templateName.split('_')[1]
    }
    if (entity === 'LABEL_SIZE') {
      return templateName.split('_')[2]
    }
    if (entity === 'DISPLAY_NAME') {
      return templateName.split('_')[3]
    }
  } catch (e) {
    return ''
  }
}

const JSONtoApiObject = (targetOrgId: string) => (jsonObj: any) => {
  return {
    ...(jsonObj.dbTemplateId ? { where: { id: jsonObj.dbTemplateId } } : {}),
    data: {
      sku: {
        connect: {
          id: jsonObj.catalogSkuId,
        },
      },
      ...(!jsonObj.dbTemplateId ? { organizationId: targetOrgId } : {}),
      organizationId: targetOrgId,
      templateId: jsonObj.name,
      displayName: jsonObj.displayName,
      imageUrl: jsonObj.imageUrl,
    },
  }
}

const TemplatesImportDialog = ({
  onSuccess,
  currentOrganizationId,
  closeDialog,
}: {
  onSuccess: () => Promise<void>
  currentOrganizationId: string
  closeDialog: () => void
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const [numCreated, setNumCreated] = useState<number>(0)
  const [numUpdated, setNumUpdated] = useState<number>(0)
  const [numErrors, setNumErrors] = useState<number>(0)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any[]>()
  const [spiTemplates, setSpiTemplates] = useState<any[]>()
  const [filteredTemplates, setFilteredTemplates] = useState<any[]>()
  const selectedOrganizationVar = useReactiveVar(selectedOrganization)
  const [brandId, setBrandId] = useState<string>()
  const [brand, setBrand] = useState<Brand>()

  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false)
  const [dataTemplates, setDataTemplates] = useState<any>(undefined)
  const getAllDesignerTemplatesRef = useRef<GetAllDesignerTemplatesRef>(null)

  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(false)
  const [dataCatalog, setDataCatalog] = useState<any>(undefined)
  const getAllCatalogSkusRef = useRef<GetAllCatalogSkusRef>(null)

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<TemplateImportFieldProps>({
    shouldFocusError: true,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<TemplateImportFieldProps>(DisplayingErrorMessagesSchema as any),
    defaultValues: initialValues,
  })

  const [getBrand] = useLazyQuery(BRANDS_LIST)

  const fetchBrandDetails = async (brandId: string) => {
    await getBrand({
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          id: { equals: brandId },
        },
      },
      onCompleted: (data) => {
        setBrand(data.listBrands[0])
      },
    })
  }

  useEffect(() => {
    if (brandId) {
      fetchBrandDetails(brandId)
    }
  }, [brandId])

  useEffect(() => {
    const fetchTemplatesfromSPI = async (brandName: string) => {
      const spiClient = new SpiClient()
      const templates = await spiClient.listTemplates({ category: brandName })
      setSpiTemplates(
        await Promise.all(
          templates.map(async (x: any) => ({
            ...x,
            imageUrl: await spiClient.getTemplatePreviewUrl(x.name),
          }))
        )
      )
    }
    if (brand?.name) {
      fetchTemplatesfromSPI(brand.name)
    }
  }, [brand])

  useEffect(() => {
    !_.isEmpty(getValues('brandId')) && setBrandId(getValues('brandId'))
  }, [watch('brandId')])

  useEffect(() => {
    getAllCatalogSkusRef.current?.refresh()
  }, [brandId])

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    onCompleted() {
      setNumCreated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted() {
      setNumUpdated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const onSubmit = async (values: TemplateImportFieldProps) => {
    const requestsBody = (selectedRows || []).map(JSONtoApiObject(currentOrganizationId))

    for (const x of requestsBody) {
      if (x.where) {
        await updateTemplate({
          variables: x,
        })
      } else {
        await createTemplate({
          variables: x,
        })
      }
    }
    setSubmitted(true)
    getAllDesignerTemplatesRef.current?.refresh()
  }

  useEffect(() => {
    console.log('Updating filteredTemplates', { spiTemplates, dataCatalog, brand, dataTemplates })
    if (_.isEmpty(brand) || _.isEmpty(spiTemplates)) {
      return
    }
    const filteredTemplates =
      (spiTemplates || [])
        // ?.filter((x) =>
        //   _.includes(
        //     _.toLower(_.camelCase(brand?.name)),
        //     _.toLower(_.camelCase(getFromTemplateName(x.name, 'BRAND')))
        //   )
        // )
        .map((x) => ({
          ...x,
          sku: getFromTemplateName(x.name, 'SKU'),
          labelSize: getFromTemplateName(x.name, 'LABEL_SIZE'),
          displayName: _.startCase(getFromTemplateName(x.name, 'DISPLAY_NAME')),
          catalogSkuId: dataCatalog?.find(
            (s: Sku) =>
              _.toLower(s.sku) === _.toLower(getFromTemplateName(x.name, 'SKU')) ||
              _.toLower(s.skuWhiteLabel) === _.toLower(getFromTemplateName(x.name, 'SKU'))
          )?.id,
          dbTemplateId: dataTemplates?.find((t: DesignerTemplate) => t.templateId === x.name)?.id,
        })) || []
    setFilteredTemplates(filteredTemplates)
  }, [spiTemplates, dataCatalog, brand, dataTemplates])

  const rows = filteredTemplates || []

  return (
    <>
      <GetAllDesignerTemplates
        ref={getAllDesignerTemplatesRef}
        brandId={brandId}
        onLoading={(v) => setLoadingTemplates(v)}
        onData={(d) => setDataTemplates(d)}
      />
      <GetAllCatalogSkus
        ref={getAllCatalogSkusRef}
        brandId={brandId}
        onLoading={(v) => setLoadingCatalog(v)}
        onData={(d) => setDataCatalog(d)}
      />
      <DialogTitle>Import Templates</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingX: '30px' }}>
            {submitError && <Alert severity={'error'}>{submitError}</Alert>}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'baseline',
              }}
            >
              <Box>Select Brand</Box>
              <Box sx={{ flexGrow: '1' }}>
                <BrandSelect
                  control={control}
                  organizationId={currentOrganizationId}
                  name='brandId'
                  label='Brand'
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '400px',
                fontSize: '10px',
                overflow: 'auto',
                border: '1px solid gray',
              }}
            >
              {loadingCatalog || loadingTemplates || spiTemplates === undefined ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {brand?.name ? (
                    <>
                      <CircularProgress />
                      {loadingCatalog && <Typography variant={'h6'}>Loading catalog</Typography>}
                      {loadingTemplates && <Typography variant={'h6'}>Loading known templates</Typography>}
                      {!spiTemplates && <Typography variant={'h6'}>Fetching new templates</Typography>}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <DataGridPremium
                  sx={{
                    '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox .Mui-disabled ': {
                      // "hide" disabled checkboxes by making it translucent
                      color: 'rgba(0, 0, 0, 0.00)',
                    },
                  }}
                  onRowSelectionModelChange={(ids) => {
                    setSelectedRows(ids.map((id) => rows.find((row) => row.id === id)))
                  }}
                  loading={loadingTemplates || loadingCatalog}
                  hideFooter={true}
                  density={'compact'}
                  checkboxSelection={true}
                  columns={[
                    {
                      field: 'Type',
                      width: 90,
                      disableColumnMenu: true,
                      renderCell: (data) => {
                        if (!dataCatalog) {
                          return '<pending>'
                        }
                        if (!data.row.catalogSkuId) return <></>
                        const found = data.row.dbTemplateId
                        return found ? (
                          <Box sx={{ color: 'darkorange', fontWeight: 700 }}>Update</Box>
                        ) : (
                          <Box sx={{ color: 'darkgreen', fontWeight: 700 }}>New</Box>
                        )
                      },
                    },
                    {
                      field: 'id',
                      headerName: 'TID',
                      width: 100,
                    },
                    {
                      field: 'sku',
                      headerName: 'SKU',
                      width: 200,
                      renderCell: (x) => {
                        return x.row.catalogSkuId ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: '8px',
                            }}
                          >
                            <CheckCircle sx={{ color: 'green' }} />
                            <div>{x.row.sku}</div>
                          </Box>
                        ) : (
                          <Tooltip title={`Matching SKU not found in catalog for ${x.row.sku}`}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                gap: '8px',
                              }}
                            >
                              <ReportProblemIcon sx={{ color: 'darkred' }} />
                              <div>{x.row.sku}</div>
                            </Box>
                          </Tooltip>
                        )
                      },
                    },
                    {
                      field: 'displayName',
                      headerName: 'Display Name',
                      width: 170,
                    },
                    { field: 'labelSize', headerName: 'Label Size', width: 140 },
                    {
                      field: 'name',
                      headerName: 'Template ID (Name)',
                      width: 350,
                    },
                  ]}
                  isRowSelectable={(params) => params.row.catalogSkuId}
                  rows={rows}
                  getRowId={(x: any) => x['id']}
                />
              )}
            </Box>

            {(filteredTemplates?.length || numCreated > 0 || numUpdated > 0 || numErrors > 0) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <div>
                  Found {filteredTemplates?.length} templates. Matched{' '}
                  {filteredTemplates?.filter((x) => x.catalogSkuId).length} templates by SKU.
                </div>
                <div>{selectedRows?.length || 0} selected for import.</div>
                <div>
                  {numCreated > 0 && <>Created {numCreated} templates. </>}
                  {numUpdated > 0 && <>Updated {numUpdated} templates. </>}
                  {numErrors > 0 && <>{numErrors} errors.</>}
                </div>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={async () => {
              await onSuccess()
            }}
          >
            Cancel
          </Button>
          {submitted ? (
            <Button
              onClick={async () => {
                await onSuccess()
              }}
            >
              Close
            </Button>
          ) : (
            <Button
              type='submit'
              disabled={!dataCatalog || isSubmitting || !(isDirty && isValid) || !selectedRows?.length}
            >
              Import
            </Button>
          )}
        </DialogActions>
      </form>
    </>
  )
}

export default TemplatesImportDialog
