import { useState, useCallback } from 'react'
import { useQuery, useReactiveVar, useMutation } from '@apollo/client'
import { Box, Typography, Button, Dialog, Paper, LinearProgress } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridRowSelectionModel,
  GridSortModel,
  GridFilterModel,
  getGridStringOperators,
} from '@mui/x-data-grid-premium'
import _ from 'lodash'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Sku } from '@/lib/graphql/types'
import { organizationId } from 'config/cache.ts'
import { CATALOG_LIST } from 'lib/graphql/listCatalog.ts'
import dayjs from 'dayjs'

import SkuCreateOrUpdateDialog from 'components/Dialogs/SkuCreateOrUpdateDialog.tsx'
import { DELETE_SKU } from '@/lib/graphql/deleteSku'
import { COUNT_SKUS } from '@/lib/graphql/countSkus'
import CatalogImportDialog from 'pages/VendorApp/Dialogs/CatalogImportDialog.tsx'
import { getUserRole, ROLES } from 'lib/rbac.tsx'

const getFilterValue = (operator: string, value: string, field: string) => {
  const skuFactItems = ['productName', 'size', 'privateLabelName']
  const brandItems = ['brand']
  if (!skuFactItems.includes(field) && !brandItems.includes(field)) {
    return {
      [field]: {
        [operator]: value,
      },
    }
  } else if (brandItems.includes(field)) {
    return {
      [field]: {
        name: {
          [operator]: value,
        },
      },
    }
  } else
    return {
      skuFact: {
        [field]: {
          [operator]: value,
        },
      },
    }
}

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

type SkuProps = Sku & { brand: string }

type ToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const PAGE_SIZE = 100

const CreateToolbar = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableEditButton,
  enableDeleteButton,
}: ToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

const filterOperators = getGridStringOperators().filter(
  (operator) => !['isEmpty', 'isNotEmpty', 'isAnyOf'].includes(operator.value)
)

const columns: GridColDef[] = [
  { field: 'brand', headerName: 'Brand', flex: 1, filterOperators },
  { field: 'sku', headerName: 'SKU', flex: 1, filterOperators },
  { field: 'productName', headerName: 'Product name', flex: 1, filterOperators },
  { field: 'size', headerName: 'Size', flex: 0.7, filterOperators },
  { field: 'skuWhiteLabel', headerName: 'SKU PL', flex: 1, filterOperators },
  { field: 'privateLabelName', headerName: 'PL Product name', flex: 1, filterOperators },
  // {
  //   field: 'createdAt',
  //   headerName: 'Created',
  //   flex: 1,
  //   renderCell: (v) => formatDateTime(v.value),
  // },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    flex: 1,
    filterable: false,
    renderCell: (v) => formatDateTime(v.value),
  },
]

export const Catalog = () => {
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [showModal, setShowModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [focusedSku, setFocusedSku] = useState<SkuProps>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const currentOrganizationId = useReactiveVar(organizationId)

  const userRole = getUserRole()

  const [queryOptions, setQueryOptions] = useState<{ sortModel: any[]; filterModel: any }>({
    sortModel: [{ field: 'updatedAt', sort: 'desc' }],
    filterModel: {},
  })

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions((queryOptions) => ({ ...queryOptions, sortModel: [...sortModel] }))
  }, [])

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions((queryOptions) => ({ ...queryOptions, filterModel: { ...filterModel } }))
  }, [])

  const { data: dataCountSkus, loading: loadingCountSkus } = useQuery(COUNT_SKUS, {
    variables: {
      where: {
        AND: [
          {
            [queryOptions.filterModel.logicOperator?.toUpperCase() || 'AND']:
              queryOptions.filterModel.items?.map((f: any) => getFilterValue(f.operator, f.value, f.field)),
          },
        ],
        brand: {
          organization: {
            id: {
              equals: currentOrganizationId,
            },
          },
        },
        archivedAt: null,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const {
    refetch: refetchCatalog,
    data: dataCatalog,
    loading: loadingCatalog,
    error: errorCatalog,
    fetchMore: fetchMoreCatalog,
  } = useQuery(CATALOG_LIST, {
    variables: {
      orderBy: queryOptions?.sortModel?.map((m) => {
        const skuFactItems = ['productName', 'size', 'privateLabelName']
        const brandItems = ['brand']
        const query: any = {}
        if (skuFactItems.includes(m.field)) {
          query.skuFact = {
            [m.field]: m.sort === 'asc' ? 'ASC' : 'DESC',
          }
        } else if (brandItems.includes(m.field)) {
          query.brand = {
            name: m.sort === 'asc' ? 'ASC' : 'DESC',
          }
        } else {
          query[m.field] = m.sort === 'asc' ? 'ASC' : 'DESC'
        }
        return query
      }),
      where: {
        AND: [
          {
            [queryOptions.filterModel.logicOperator?.toUpperCase() || 'AND']:
              queryOptions.filterModel.items?.map((f: any) => getFilterValue(f.operator, f.value, f.field)),
          },
        ],
        brand: {
          organization: {
            id: {
              equals: currentOrganizationId,
            },
          },
        },
        archivedAt: null,
      },
      offset: 0,
      limit: PAGE_SIZE,
    },
    notifyOnNetworkStatusChange: true,
  })

  const countSkus = dataCountSkus?.countSkus || 0

  const handleOnRowsScrollEnd = async () => {
    if (dataCatalog?.listSkus?.length < countSkus) {
      await fetchMoreCatalog({
        variables: { offset: dataCatalog?.listSkus?.length, limit: PAGE_SIZE },
      })
    }
  }

  const [deleteBrand] = useMutation(DELETE_SKU)

  function closeDialog() {
    setShowModal(false)
  }

  const rows = (dataCatalog?.listSkus || []).map((sku: Sku) => {
    return {
      ...sku,
      brand: sku.brand?.name,
      privateLabelName: sku.skuFact.privateLabelName,
      productName: sku.skuFact.productName,
      size: sku.skuFact.size,
    }
  })

  if (!currentOrganizationId) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Catalog</Typography>
      </Box>
      {userRole === ROLES.ADMIN && (
        <Box sx={{ width: '100', display: 'flex', justifyContent: 'right' }}>
          <Button onClick={() => setShowImportModal(true)}>Import Catalog</Button>
        </Box>
      )}
      <Box sx={{ height: 'calc(100vh - 240px)' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
            }}
            rowCount={countSkus}
            density={'compact'}
            sortingMode={'server'}
            filterMode={'server'}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={onFilterChange}
            checkboxSelection
            rows={rows}
            hideFooterPagination={true}
            scrollEndThreshold={100}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            columns={columns}
            loading={loadingCatalog}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = rows.find((r: Sku) => r.id === newRowSelectionModel[0])
                setFocusedSku(data)
              } else {
                setFocusedSku(undefined)
              }
            }}
            slots={{
              toolbar: CreateToolbar,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedSku,
                enableDeleteButton: !!focusedSku,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleEdit: () => {
                  setMode('update')
                  focusedSku && setShowModal(true)
                },
                handleDelete: async () => {
                  await deleteBrand({
                    variables: {
                      where: {
                        id: focusedSku?.id,
                      },
                    },
                  })
                  await refetchCatalog()
                },
              },
            }}
          />
        </Paper>
      </Box>

      <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth maxWidth='md'>
        <SkuCreateOrUpdateDialog
          mode={mode}
          data={focusedSku}
          closeDialog={() => closeDialog()}
          currentOrganizationId={currentOrganizationId}
          onSuccess={async () => {
            setRowSelectionModel([])
            setFocusedSku(undefined)
            await refetchCatalog()
          }}
        />
      </Dialog>
      <Dialog open={showImportModal} onClose={() => setShowImportModal(false)} fullWidth maxWidth='md'>
        <CatalogImportDialog
          closeDialog={() => setShowImportModal(false)}
          currentOrganizationId={currentOrganizationId}
          onSuccess={async () => {
            setRowSelectionModel([])
            setFocusedSku(undefined)
            await refetchCatalog()
          }}
        />
      </Dialog>
    </>
  )
}
