import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client'
import AppSyncConfig from '../config/AppSyncConfig'
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import React from 'react'
import { RetryLink } from '@apollo/client/link/retry'
import {
  organizationId,
  orderSearchFilter,
  fromDate,
  toDate,
  client,
  productsSearchFilter,
  selectedOrganization,
} from 'config/cache'
import _ from 'lodash'
import { CognitoOperations } from './Cognito'
import { customerUpdater, ordersUpdate } from './shopifyDataHandler'

export const tokenVar = makeVar(undefined)
const cognitoOps = new CognitoOperations(
  import.meta.env.VITE_COGNITO_USER_POOL_ID,
  import.meta.env.VITE_COGNITO_CLIENT_ID
)

const getAppSyncAuth = (): AuthOptions => {
  switch (AppSyncConfig.authenticationType) {
    case AUTH_TYPE.API_KEY:
      return {
        type: AppSyncConfig.authenticationType,
        apiKey: AppSyncConfig.apiKey!,
      }
    case AUTH_TYPE.OPENID_CONNECT:
      return {
        type: AppSyncConfig.authenticationType,
        jwtToken: () => tokenVar() || '',
      }
    case AUTH_TYPE.AMAZON_COGNITO_USER_POOLS:
      return {
        type: AppSyncConfig.authenticationType,
        jwtToken: async () => {
          const session = await cognitoOps.getSession()
          return session?.getAccessToken().getJwtToken() || ''
        },
      }
    default:
      return {
        type: AUTH_TYPE.NONE,
      }
  }
}

const httpLink = createHttpLink({ uri: AppSyncConfig.graphqlEndpoint })

const link = ApolloLink.from([
  new RetryLink(),
  createAuthLink({
    url: AppSyncConfig.graphqlEndpoint!,
    region: AppSyncConfig.region!,
    auth: getAppSyncAuth(),
  }),
  createSubscriptionHandshakeLink(
    {
      url: AppSyncConfig.graphqlEndpoint!,
      region: AppSyncConfig.region!,
      auth: getAppSyncAuth(),
    },
    httpLink
  ),
])

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      LabelApprovalWorkflowInstance: {
        fields: {
          metadata: {
            merge(_, metadata) {
              return JSON.parse(metadata || '{}')
            },
          },
        },
      },
      Query: {
        fields: {
          proxyShopifyQuery: {
            keyArgs: (args) => {
              const variables = JSON.parse(args?.variables || '{}')
              return `${args?.query}${args?.shopifyStoreId}${variables?.query || 'none'}${
                variables?.sortKey || 'none'
              }${variables?.reverse ? 'F' : 'R'}`
            },
            merge(existing, incoming, { readField }) {
              // This code appends any new data to cache and make sure
              // data is not duplicated.
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.

              const apiResponse = JSON.parse(incoming)
              if (apiResponse.query === 'customers') {
                const customerData = customerUpdater(apiResponse)
                const data = existing?.[apiResponse.query]?.data
                  ? { ...existing?.[apiResponse.query]?.data }
                  : {}
                customerData.forEach((customer: any) => {
                  data[customer.id] = customer
                })

                return {
                  ...(existing || {}),
                  [apiResponse.query]: {
                    data,
                    pageInfo: apiResponse.pageInfo,
                  },
                }
              } else if (apiResponse.query === 'orders') {
                const ordersData = ordersUpdate(apiResponse)
                const data = existing?.[apiResponse.query]?.data
                  ? { ...existing?.[apiResponse.query]?.data }
                  : {}
                ordersData.forEach((order: any) => {
                  data[order.id] = order
                })

                return {
                  ...(existing || {}),
                  [apiResponse.query]: {
                    data,
                    pageInfo: apiResponse.pageInfo,
                  },
                }
              }
            },
            read(existing, { args }) {
              if (args?.query && ['customers', 'orders'].includes(args?.query)) {
                if (existing?.[args.query]) {
                  return {
                    [args?.query]: {
                      pageInfo: existing[args?.query].pageInfo,
                      data: Object.values(existing[args?.query].data),
                    },
                  }
                }
              }
            },
          },
          listSkus: {
            keyArgs: ['where', 'orderBy'],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[(args?.skip || 0) + i] = incoming[i]
              }
              return merged
            },
          },
          listSupplementFacts: {
            keyArgs: ['where', 'orderBy'],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[(args?.skip || 0) + i] = incoming[i]
              }
              return merged
            },
          },
          listLabelApprovalWorkflowInstances: {
            keyArgs: ['where'],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[(args?.skip || 0) + i] = incoming[i]
              }
              return merged
            },
          },
          listOrdersWorkflow: {
            keyArgs: ['where', 'orderBy'],
            merge(existing, incoming, { args }) {
              // This code appends any new data to cache and make sure
              // data is not duplicated.
              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[(args?.skip || 0) + i] = incoming[i]
              }
              return merged
            },
          },
          listUsers: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          listDesignerTemplates: {
            keyArgs: ['where', 'orderBy'],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[(args?.skip || 0) + i] = incoming[i]
              }
              return merged
            },
          },
          listOrders: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          listSkuListings: {
            keyArgs: ['where', 'orderBy'],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.slice(0) : []
              for (let i = 0; i < incoming.length; ++i) {
                merged[(args?.skip || 0) + i] = incoming[i]
              }
              return merged
            },
          },
          productsSearchFilter: {
            read() {
              return productsSearchFilter()
            },
          },
          orderSearchFilter: {
            read() {
              return orderSearchFilter()
            },
          },
          fromDate: {
            read() {
              return fromDate()
            },
          },
          toDate: {
            read() {
              return toDate()
            },
          },
          client: {
            read() {
              return client()
            },
          },
          organizationId: {
            read() {
              return organizationId()
            },
          },
          selectedOrganization: {
            read() {
              return selectedOrganization()
            },
          },
        },
      },
    },
  }),
})

type ProviderProps = {
  children: React.ReactNode // 👈️ type children
}

const AppSyncProvider: any = ({ children }: ProviderProps) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AppSyncProvider
