import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  Select,
  FormControl,
  MenuItem,
  Stack,
  Skeleton,
  Button,
  LinearProgress,
  Box,
  Typography,
  InputLabel,
  Dialog,
} from '@mui/material'
import _ from 'lodash'
import { startSession, upsertInstance, getInstancePreviewUrl } from '@/lib/sdi'
import { TEMPLATES_LIST } from 'lib/graphql/listTemplates.ts'
import { DesignerTemplate } from 'lib/graphql/types'

export type SkuDetails = {
  product_supplement_facts: string | undefined
  product_name: string
  product_suggested_use: string
  product_allergy_statement: string
  product_caution: string
  product_ingredients: string
}

export const LabelFactsPreview = ({ skuId, skuDetails }: { skuId: string; skuDetails: SkuDetails }) => {
  const [loadedTemplate, setLoadedTemplate] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [session, setSession] = useState('')
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const {
    refetch: _refetchTemplates,
    data: dataTemplates,
    loading: _loadingTemplates,
    error: _errorTemplates,
  } = useQuery(TEMPLATES_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        skuId: { equals: skuId },
        archivedAt: null,
      },
      limit: 300,
    },
    skip: !skuId,
  })

  const fetchTemplate = async (id: string) => {
    // if (!skuDetails.product_supplement_facts) {
    //   console.log('Waiting for image upload')
    //   return
    // }
    if (!session) {
      console.log('No session available, exiting fetchTemplate')
      return
    }
    const templateId = dataTemplates?.listDesignerTemplates.find(
      (template: DesignerTemplate) => template.id === id
    ).templateId
    if (!templateId) {
      console.log('No template name, exiting fetchTemplate')
      return
    }
    setIsLoadingImage(true)
    const randomString = Array.from(window.crypto.getRandomValues(new Uint8Array(20)), (dec) =>
      dec.toString(16).padStart(2, '0')
    ).join('')
    const instanceId = await upsertInstance(templateId, randomString, skuDetails, session)
    const url = await getInstancePreviewUrl(instanceId, session)
    setLoadedTemplate(selectedTemplate)
    setIsLoadingImage(false)
    setImageUrl(url)
  }

  useEffect(() => {
    if (!session) {
      const run = async () => {
        setSession(await startSession())
      }
      run()
    }
  }, [session, setSession])

  const lookupTemplateNameById = (id: string) => {
    const template = (dataTemplates?.listDesignerTemplates || []).find(
      (template: DesignerTemplate) => template.id === id
    )
    return template?.displayName || ''
  }

  return (
    <>
      <Stack direction='column' spacing={2}>
        {!dataTemplates?.listDesignerTemplates ? (
          <LinearProgress />
        ) : (
          <Stack direction='row' spacing={2}>
            <FormControl variant='standard' sx={{ minWidth: '280px', width: '50%' }}>
              <InputLabel>Select a template to preview</InputLabel>
              <Select
                value={selectedTemplate}
                name='template'
                disabled={isLoadingImage}
                onChange={(event) => {
                  setSelectedTemplate(event.target.value)
                }}
              >
                {(dataTemplates?.listDesignerTemplates || []).map((template: DesignerTemplate) => {
                  return (
                    <MenuItem key={template.id} value={template.id}>
                      {template.displayName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Button
              sx={{ paddingTop: '15px', width: '100px' }}
              disabled={isLoadingImage || loadedTemplate === selectedTemplate}
              onClick={() => {
                fetchTemplate(selectedTemplate)
              }}
            >
              Preview
            </Button>
          </Stack>
        )}
        {isLoadingImage ? (
          <Box>
            <Typography variant='body1' sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
              {<b>{lookupTemplateNameById(selectedTemplate)}</b>} Rendering...
            </Typography>
            <Skeleton animation='wave' height='220px' sx={{ transform: 'none' }} />
          </Box>
        ) : (
          !_.isEmpty(imageUrl) && (
            <Box>
              <Typography
                variant='body1'
                sx={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                }}
              >
                <b>{lookupTemplateNameById(loadedTemplate)}</b> Preview
              </Typography>
              <img src={imageUrl} width={'100%'} onClick={() => setShowModal(true)} />
            </Box>
          )
        )}
      </Stack>
      <Dialog
        // className={styles.modelAddNewCustomerGroup}
        open={showModal}
        onClose={() => setShowModal(false)}
        fullWidth
        maxWidth='lg'
      >
        <img src={imageUrl} width='100%' onClick={() => setShowModal(true)} />
      </Dialog>
    </>
  )
}
