import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import { ShopifyCustomer, SkuDetails } from '@/lib/types'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useQuery } from '@tanstack/react-query'

import { createInstance, getTemplateDesignerUrl, readInstance } from '@/lib/sdi'
import { UPSERT_PRIVATE_LABEL } from 'lib/graphql/upsertPrivateLabel.ts'
import { PrivateLabel, ProductVariant, ShopifyProduct } from 'pages/VendorApp/Shop/types.ts'

import { selectedStoreId, shoppingProduct, stores } from 'config/cache.ts'
import { MD5 } from 'crypto-js'
import { useNavigate } from 'react-router-dom'

interface LabelDesignerProps {
  variantId: string
  sku: string
  open: boolean
  onClose: any
  customer: ShopifyCustomer
  product: ShopifyProduct
  label?: PrivateLabel
  mode?: string | null
  referrer?: string | null | undefined
}

interface StepProps {
  active: boolean
  setNextDisabled?: (flag: boolean) => void
  next?: (data: any) => void
  handleNext?: (data: any) => void
}

type SpiVar = {
  name: string
  value: string
}

type AddCartPayload = {
  address: string | null
  background: {
    id: string
    url: string
  }
  barcodeId: string
  colorThemeId: string | null
  instanceId: number
  job3D: {
    id: number
    url: string
  }
  jobPDF: {
    id: number
    url: string
  }
  logo: {
    id: string
    url: string
  }
  productName: string
}

const steps = ['Select a Template', 'Customize template', 'Summary']

const SelectTemplate: React.FC<StepProps & { templates: any[]; setTemplateId: (id: string) => void }> = ({
  active,
  templates,
  setNextDisabled,
  setTemplateId,
  handleNext,
}) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState()

  React.useEffect(() => {
    if (setNextDisabled) {
      setNextDisabled(selectedTemplate === undefined)
    }
  }, [setNextDisabled, selectedTemplate])

  if (!active) {
    return <></>
  }
  return (
    <Box sx={{ overflow: 'auto', height: '100%' }}>
      <Grid container spacing={2}>
        {templates.map((template) => (
          <Grid
            item
            xs={4}
            key={template.templateId}
            onClick={() => {
              setSelectedTemplate(template.templateId)
              setTemplateId(template.templateId)
            }}
          >
            <Box sx={{ padding: '7px', border: selectedTemplate === template.templateId ? 3 : 0 }}>
              {selectedTemplate === template.templateId && (
                <Box sx={{ float: 'right' }}>
                  <Button
                    sx={{ paddingBottom: '0px', paddingTop: '0px', backgroundColor: '#47C27E' }}
                    onClick={handleNext}
                    size='small'
                    variant='contained'
                  >
                    Confirm
                  </Button>
                </Box>
              )}
              <p>{template.displayName}</p>
              <img src={template.imageUrl} width='100%' />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const CustomizeTemplate: React.FC<
  StepProps & {
    email: string
    username: string
    instanceId?: string
    templateId?: string
    userAccessToken?: string
    skuDetails: SkuDetails
    handleBack: (v?: string) => void
  }
> = ({
  email,
  username,
  active,
  setNextDisabled,
  next,
  instanceId,
  templateId,
  userAccessToken,
  skuDetails,
  handleBack,
}) => {
  const defaultUserSpiToken = MD5(email.toLowerCase()).toString()
  const [instanceUserAccessToken] = React.useState<string>(userAccessToken || defaultUserSpiToken)
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false)

  //TODO: Convert to useEffect
  const { data: newInstanceId, isError: isInstanceError } = useQuery({
    // queryKey: ['instanceId', templateId, instanceUserAccessToken],
    queryFn: () => {
      if (!templateId) {
        return
      }
      return createInstance(templateId, username, skuDetails, instanceUserAccessToken)
    },
    enabled: active && !instanceId,
    refetchOnWindowFocus: false,
    staleTime: 30 * 1000, //30 seconds: This call will fail if template/userAccessToken already exist
  })

  useEffect(() => {
    if (!active || !templateId) {
      return
    }
    setNextDisabled && setNextDisabled(true)
    const handler = async (ev: MessageEvent<{ command: string; data: any }>) => {
      const { command } = ev.data

      switch (command) {
        case 'goBack': {
          setShowConfirmation(true)
          break
        }
        case 'addCart': {
          console.log('Adding to Cart')
          const payload = ev.data.data as AddCartPayload
          const instanceId = payload.instanceId
          const instanceData = await readInstance(instanceId, instanceUserAccessToken)
          const spiVars = instanceData?.variables?.spi_vars as SpiVar[]

          setNextDisabled && setNextDisabled(false)
          next &&
            next({
              userAccessToken: instanceUserAccessToken,
              payload,
              spiVars,
            })
          break
        }
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [active, templateId, setNextDisabled, userAccessToken])

  if (!active) {
    return <></>
  }
  if (!templateId) {
    return <></>
  }

  if (!instanceId && !newInstanceId) {
    return (
      <Box
        sx={{
          marginTop: '20%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={100} />
      </Box>
    )
  }

  if (isInstanceError) {
    return <p>Error</p>
  }

  const iframeUrl = getTemplateDesignerUrl(instanceUserAccessToken, instanceId || newInstanceId)

  return (
    <>
      <iframe
        id='designer-iframe'
        style={{
          width: '100%',
          height: '100%',
        }}
        src={iframeUrl.toString()}
      />
      <Dialog
        fullWidth={true}
        // className={styles.modelAddNewCustomerGroup}
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <DialogTitle>Warning: Your changes will be lost!</DialogTitle>
        <DialogContent>
          <Alert
            // onClose={() => setShowErrorModal(undefined)}
            severity='error'
          >
            Modifications to this label will be lost if you return to template selection.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowConfirmation(false)
            }}
          >
            Continue editing
          </Button>
          <Button
            onClick={() => {
              setShowConfirmation(false)
              handleBack()
            }}
            sx={{ color: 'red' }}
          >
            Go to template selection
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DefaultButtons = ({
  customerName,
  handleBack,
  referrer,
}: {
  customerName: string
  handleBack: (v?: string) => void
  referrer?: string | null
}) => {
  const [disabled, setDisabled] = useState<boolean>(true)
  setTimeout(() => {
    setDisabled(false)
  }, 5000)

  return (
    <>
      <Button
        disabled={disabled}
        variant={'contained'}
        color={'success'}
        onClick={() => handleBack(referrer || 'catalog')}
      >
        Back to {customerName}'s {referrer === 'mpl' ? 'My Private Labels' : 'Catalog'}
        {disabled && <CircularProgress />}
      </Button>
    </>
  )
}

const SummaryStep: React.FC<{
  active: boolean
  customerName: string
  error?: string
  isEdit: boolean
  isRevision: boolean
  labelImageUrl?: string
  instanceId?: string
  referrer?: string | undefined | null
  handleBack: (destination?: string) => void
}> = ({ active, customerName, isEdit, error, labelImageUrl, handleBack, referrer }) => {
  const navigate = useNavigate()
  if (!active) {
    return <></>
  }
  return (
    <Box
      sx={{
        paddingTop: '20px',
        width: '100vw',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <>
        {error ? (
          <Alert severity={'error'} variant={'outlined'}>
            {error}
          </Alert>
        ) : (
          <>
            <Box>
              <Typography variant={'h6'}>
                The private label has been {isEdit ? 'updated' : 'saved'} in {customerName}'s account.
              </Typography>
            </Box>
            {labelImageUrl ? (
              <Box sx={{ maxWidth: '700px', paddingY: '10px' }}>
                <Paper elevation={5} sx={{ display: 'flex' }}>
                  <img src={labelImageUrl} width={'100%'} />
                </Paper>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
        <>
          <Box sx={{ display: 'flex', gap: '32px' }}>
            <DefaultButtons handleBack={handleBack} customerName={customerName} referrer={referrer} />
          </Box>
        </>
      </>
    </Box>
  )
}

function formatPhoneNumber(phoneNumberString?: string) {
  if (!phoneNumberString) return null
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^[1]?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const LabelDesigner: React.FC<LabelDesignerProps> = ({
  open,
  onClose,
  sku,
  label,
  product,
  customer,
  variantId,
  mode,
  referrer,
}) => {
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const isEdit = !!label
  const isRevision = mode === 'revise'
  const [isNextDisabled, setNextDisabled] = React.useState<boolean>(false)
  const [activeStep, setActiveStep] = React.useState(isEdit ? 1 : 0)
  const [templateId, setTemplateId] = React.useState<string | undefined>(label?.templateId)
  const [upsertPrivateLabel] = useMutation(UPSERT_PRIVATE_LABEL)
  const [error, setError] = React.useState<string>()

  const internalOnclose = () => {
    reset()
    onClose()
  }
  const reset = () => {
    setTemplateId(undefined)
    setActiveStep(0)
  }
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      internalOnclose()
      return
    }
    setNextDisabled(false)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = (destination?: string) => {
    if (destination === 'catalog') {
      shoppingProduct(undefined)
      internalOnclose()
    } else if (destination === 'mpl') {
      shoppingProduct(undefined)
      internalOnclose()
    } else {
      setNextDisabled(false)
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const designTemplates = _.sortBy(
    JSON.parse(
      (product.variants as ProductVariant[]).find((variant) => variant.id === variantId)?.designTemplates
        ?.value || '[]'
    ),
    ['displayName']
  )

  const storeConfig = (storesVar || []).find((store) => store.id === selectedStoreIdVar) || {}

  const skuDetails = SkuDetails.parse(
    _.omitBy(
      {
        ...JSON.parse(product.skuDetails.value),
        product_name: product.title,
        practitioner_practice_name: customer.defaultAddress?.company || 'Company Name',
        practitioner_address: customer.defaultAddress?.address1 || 'Address',
        practitioner_city: customer.defaultAddress?.city || 'City',
        practitioner_state: customer.defaultAddress?.provinceCode || 'State',
        practitioner_zip: customer.defaultAddress?.zip || 'Zip',
        practitioner_metawebsite_url:
          (customer.website?.value || '').split('://').pop() || 'Your website URL',
        practitioner_contact_email: customer.email || 'Your email address',
        practitioner_contact_phone: formatPhoneNumber(customer.phone) || 'Your phone number',
      },
      _.isNull
    )
  )

  const storeSkuDetails: SkuDetails = {
    ...skuDetails,
    product_supplement_facts: _.isEmpty(skuDetails.product_supplement_facts)
      ? undefined
      : `https://${storeConfig.assetsDomain}/${skuDetails.product_supplement_facts}`,
  }

  return (
    <Box sx={{ height: '700px' }}>
      <DialogTitle>
        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>
          <IconButton edge='start' color='inherit' onClick={internalOnclose} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
            } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent sx={{ height: '600px', overflow: 'unset' }}>
        <SelectTemplate
          active={activeStep === 0}
          templates={designTemplates}
          setNextDisabled={setNextDisabled}
          setTemplateId={setTemplateId}
          handleNext={handleNext}
        />
        <CustomizeTemplate
          active={activeStep === 1}
          setNextDisabled={setNextDisabled}
          userAccessToken={label?.userAccessToken}
          instanceId={label?.instanceId}
          templateId={templateId}
          skuDetails={storeSkuDetails}
          username={`${customer.firstName}${customer.lastName}`}
          email={customer.email!}
          handleBack={(v) => handleBack(v)}
          next={async (context: { userAccessToken: string; payload: AddCartPayload; spiVars: SpiVar[] }) => {
            const t = new Date()
            await upsertPrivateLabel({
              variables: {
                customerId: customer.id,
                shopifyStoreConfigurationId: selectedStoreIdVar,
                privateLabel: JSON.stringify({
                  sku: sku,
                  productName:
                    context.payload.productName ||
                    context.spiVars.find((v) => v.name === 'product_name')?.value,
                  instanceId: context.payload.instanceId.toString(),
                  templateId: templateId!,
                  job3DUrl: context.payload.job3D.url,
                  userAccessToken: context.userAccessToken,
                  status: label?.status || 'DRAFT',
                  variantId: variantId,
                  productId: product.id,
                  brandedName: context.spiVars.find((v) => v.name === 'product_name')?.value,
                  createdAt: t.toISOString(),
                  updatedAt: t.toISOString(),
                  labelFactsVersion: storeSkuDetails.label_facts_version,
                  revisionNumber: storeSkuDetails.revision_number,
                }),
              },
            })
            handleNext()
          }}
        />
        <SummaryStep
          active={activeStep === 2}
          error={error}
          isEdit={isEdit}
          isRevision={isRevision}
          // instanceId={instanceId}
          customerName={`${customer.firstName} ${customer.lastName}`}
          // labelImageUrl={
          //   context.payload.job3D.url
          // }
          referrer={referrer}
          handleBack={handleBack}
        />
      </DialogContent>
    </Box>
  )
}
