import { useDropzone } from 'react-dropzone'
import { useLazyQuery } from '@apollo/client'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { IconButton, Box, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import style from 'components/Dialogs/dialogs.module.css'
import { GET_PRESIGNED_URL_FOR_PUBLIC_ASSET } from 'lib/graphql/getPresignedUrlForPublicAsset'
import { useEffect, useState } from 'react'

type AssetType = 'logo' | 'banner' | 'supplementFact'

type StoreImageProps = {
  assetType: AssetType
  key?: string
  path?: string
  preview?: string
  alt?: string
  contentType?: string
  field?: string
  stored: boolean
}

type DirectUploadProps = {
  value: string | undefined
  assetType: AssetType
  disabled: boolean
  setValue: (value: string | undefined) => void
  orgId?: string
  storeId?: string
  onStarted?: () => void
  onFinished?: () => void
}

export const DirectUpload: React.FC<DirectUploadProps> = ({
  value,
  assetType,
  disabled,
  setValue,
  orgId,
  storeId,
  onStarted,
  onFinished,
}) => {
  const [image, setImage] = useState<StoreImageProps | undefined>({
    stored: true,
    assetType,
    preview: value,
  })
  const [getPresignedUrlForPublicAsset] = useLazyQuery(GET_PRESIGNED_URL_FOR_PUBLIC_ASSET)
  const { getInputProps, getRootProps } = useDropzone({
    disabled,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const copyImages = acceptedFiles.map((file) => {
        return {
          ...file,
          stored: false,
          assetType: assetType,
          alt: file.name,
          contentType: file.type,
          preview: URL.createObjectURL(file),
        }
      })
      setImage(copyImages[0])
    },
  })

  useEffect(() => {
    const startUpload = async (image: StoreImageProps | undefined) => {
      if (image) {
        if (!image.stored) {
          if (onStarted) {
            onStarted()
          }
          const result = await uploadFile(image)
          setValue(result.key)
          if (onFinished) {
            onFinished()
          }
        }
      }
    }
    startUpload(image)
  }, [image])

  const thumb = (file: any) => {
    const url = file.preview
    return (
      <Box className={style.thumb}>
        <Box className={style.thumbInner}>
          {url ? (
            <img
              width={'100%'}
              src={url}
              className={style.productImg}
              style={{ background: 'white', verticalAlign: 'middle' }}
            />
          ) : (
            <>Could not load image.</>
          )}
        </Box>
      </Box>
    )
  }

  async function readFile(filename: string) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => reject()
      reader.onerror = () => reject()
      reader.onload = () => resolve(reader.result)

      const response = await fetch(filename)
      const responseBlob = await response.blob()
      return reader.readAsArrayBuffer(responseBlob)
    })
  }

  async function getPresignedUploadUrl(file: {
    filename: string
    contentType: string
    storeId?: string
    orgId?: string
  }) {
    const response = await getPresignedUrlForPublicAsset({
      variables: {
        storeId: file.storeId,
        type: assetType,
        orgId: file.orgId,
        filename: file.filename,
        method: 'PUT',
        contentType: file.contentType,
      },
    })
    if (response.error) {
      throw new Error(JSON.stringify(response.error))
    }
    return response.data.getPresignedUrlForPublicAsset
  }

  async function uploadFile(file: StoreImageProps) {
    if (!storeId && !orgId) {
      console.log('Neither store nor org id not set, cannot start upload')
      return
    }
    const [presignedUploadObject, data] = await Promise.all([
      getPresignedUploadUrl({
        filename: file.path!,
        contentType: file.contentType!,
        storeId: storeId,
        orgId: orgId,
      }),
      readFile(file.preview as string),
    ])

    const res = await fetch(presignedUploadObject.presignedUrl, {
      method: 'PUT',
      body: data as BodyInit,
      headers: {
        'Content-Type': file.contentType as string,
      },
    })

    if (res.ok) {
      return {
        ...presignedUploadObject,
        assetType: assetType,
        contentType: 'IMAGE',
        alt: file.path,
        stored: true,
      }
    } else {
      console.log('ERROR')
      console.log(res)
    }
  }

  const hasPreview = image?.preview

  return (
    <Box
      {...getRootProps({
        style,
      })}
      className={style.dropzone}
      sx={{
        border: '1px dashed #008bbd',
        background: '#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '5px',
        marginBottom: '15px',
        padding: 2,
      }}
    >
      <div {...getInputProps()} />
      {!hasPreview ? (
        <Box my={1}>
          <DownloadIcon fontSize='large' sx={{ mb: -1 }} />
          <Typography variant='body1' sx={{ fontWeight: 400 }}>
            Drag-and-drop or click to add an image
          </Typography>
        </Box>
      ) : (
        <Box className={style.thumbsContainer} key={image.preview}>
          {hasPreview && (
            <>
              {thumb(image)}
              {!disabled && (
                <IconButton
                  sx={{
                    color: 'red',
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                    background: '#ffc0c0',
                    borderRadius: '50%',
                  }}
                  onClick={async (event) => {
                    event.stopPropagation()
                    setImage(undefined)
                    setValue(undefined)
                  }}
                >
                  <DeleteForeverIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
