import { gql } from '@apollo/client'

export const CATALOG_LIST = gql`
  query listSkus($where: SkuWhereInput, $limit: Int, $offset: Int, $orderBy: [SkuOrderByInput]) {
    listSkus(where: $where, take: $limit, skip: $offset, orderBy: $orderBy) {
      id
      sku
      brandId
      brand {
        id
        name
      }
      skuFact {
        privateLabelName
        productName
        size
        dimensions
      }
      skuWhiteLabel
      createdAt
      updatedAt
    }
  }
`
